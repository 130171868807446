<template>
  <div class="container">
    <Header :showBackButton="true" backText="Back to Choose Action" @back="goBackToChooseAction" />
    <div class="content" ref="content">
      <h1 class="centered-title">{{ todayNews.title }}</h1>
      <pre class="centered-content">{{ todayNewsContent }}</pre>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      todayNews: { id: 1, title: "November 8 2024", text: "/articles/8nov2024.txt" }, // Placeholder news content
      todayNewsContent: '',
    };
  },
  mounted() {
    this.fetchTodayNewsContent(this.todayNews.text);
  },
  methods: {
    fetchTodayNewsContent(url) {
      return fetch(url)
        .then(response => response.text())
        .then(content => {
          this.todayNewsContent = content;
        });
    },
    goBackToChooseAction() {
      // Navigate back to Choose Action page for News
      this.$router.push({
        name: 'choose-action',
        params: {
          topicType: 'news',
          topicId: this.todayNews.id,
          topicName: this.todayNews.title,
        },
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-family: 'Georgia', serif;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  color: #333; /* Softer color for better readability */
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .centered-title {
    font-size: 1.25rem;
  }

  .centered-content {
    font-size: 1rem;
    text-align: left;
  }
}
</style>
