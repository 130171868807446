<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Previous Page" 
      @back="goBackToPreviousPage"
    />
    <div class="content">
      <h1>Create or Join Private Topics</h1>
      <!-- Create Section -->
      <div class="create-topic-section">
        <h2>Create a Private Topic</h2>
        <input 
          type="text" 
          v-model="newTopicName" 
          placeholder="Topic Name (max 75 characters)" 
          maxlength="75" 
        />
        <input 
          type="text" 
          v-model="newTopicCode" 
          placeholder="6-character Code" 
          maxlength="6" 
        />
        <button @click="createPrivateTopic" class="create-button">Create</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
      <!-- Join Section -->
      <div class="join-topic-section">
        <h2>Join a Private Topic</h2>
        <input 
          type="text" 
          v-model="joinCode" 
          placeholder="6-character Code" 
          maxlength="6" 
        />
        <button @click="joinPrivateTopic" class="join-button">Join</button>
        <p v-if="joinErrorMessage" class="error-message">{{ joinErrorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosConfig';
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      newTopicName: '',
      newTopicCode: '',
      joinCode: '',
      errorMessage: '',
      joinErrorMessage: '',
    };
  },
  methods: {
    async createPrivateTopic() {
      if (!this.newTopicName.trim() || this.newTopicCode.trim().length !== 6) {
        this.errorMessage = 'Please provide a topic name and a 6-character code.';
        return;
      }
      try {
        const response = await axiosInstance.post('/private-topics', {
          name: this.newTopicName.trim(),
          code: this.newTopicCode.trim(),
        });
        this.$router.push({ name: 'private-suggestions', params: { topicName: this.newTopicName.trim() } });
      } catch (error) {
        this.errorMessage = 'Failed to create topic.';
      }
    },
    async joinPrivateTopic() {
      try {
        const response = await axiosInstance.post('/private-topics/join', { code: this.joinCode.trim() });
        if (response.data && response.data.topicName) {
          this.$router.push({ name: 'private-suggestions', params: { topicName: response.data.topicName } });
        } else {
          this.joinErrorMessage = 'Invalid topic code.';
        }
      } catch (error) {
        this.joinErrorMessage = 'Failed to join topic.';
      }
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.create-button,
.join-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
