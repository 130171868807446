<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Resources" 
      @back="goBackToHome" 
    />
    <div class="content">
      <h1>Menu</h1>

      <div class="resource-box" @click="goToTopicsPage">
        Public Topics
      </div>
      <div class="resource-box" @click="goToPrivateTopics">
        Private Topics
      </div>
      <div class="resource-box" @click="goToDonatePage">
        Donate
      </div>
      <div class="resource-box" @click="goToBuyVotes">
        Buy Votes
      </div>
      <div class="resource-box" @click="goToResources">
        Get More Resources
      </div>

      <!-- Added About Resuvonia and Logout buttons here -->
      <div class="resource-box" @click="goToAboutResuvonia">
        About Resuvonia
      </div>
      <div class="resource-box" @click="logout">
        Logout
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  methods: {
    goBackToHome() {
      this.$router.push({ name: 'home' });
    },
    goToTopicsPage() {
      this.$router.push({ name: 'topics' });
    },
    goToPrivateTopics() {
      this.$router.push({ name: 'private-topics' });
    },
    goToDonatePage() {
      this.$router.push({ name: 'donate' });
    },
    goToBuyVotes() {
      this.$router.push({ name: 'buy-votes' });
    },
    goToResources() {
      this.$router.push({ name: 'resources' });
    },
    goToAboutResuvonia() {
      this.$router.push({ name: 'about-resuvonia' }); // Corrected route name
    },
    logout() {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("currentUser");
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
}

.resource-box {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 20px 0;
}

.resource-box:hover {
  background-color: #555;
}
</style>
