<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Previous Page" 
      :showLogoutButton="true" 
      @back="goBackToPreviousPage" 
    />
    <div class="content">
      <h1>Donate If You Want</h1>
      <div class="payment-options">
        <button @click="donateWithStripe" class="stripe-button">Donate with Stripe</button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    donateWithStripe() {
      try {
        window.location.href = 'https://donate.stripe.com/14k5mt0zk7DXf1m9AA'; // Your Stripe donation link
      } catch (error) {
        this.errorMessage = 'There was an error with the donation process. Please try again.';
        console.error('Error during donation:', error);
      }
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.payment-options {
  margin-top: 20px;
}

.stripe-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  font-size: 16px;
}

.stripe-button:hover {
  background-color: #555;
}

.error-message {
  color: red;
  margin-top: 10px;
}

h1 {
  margin-bottom: 20px;
}
</style>
