<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Previous Page" 
      :showLogoutButton="true" 
      @back="goBackToPreviousPage" 
    />
    <div class="content">
      <h1 class="title">Theodor N. Engøy</h1>
      <img src="/theodor-engoy.png" alt="Theodor N. Engøy" class="founder-image">
      <h2>Founder of Resuvonia</h2>
      <h3>Contact Resuvonia</h3>
      <a href="mailto:resuvonia@gmail.com" class="contact-logo">
        <img src="/gmail-logo.png" alt="Gmail" class="social-logo">
      </a>
      <h3>Resuvonia’s Social Media</h3>
      <div class="social-media-links">
        <a href="https://www.youtube.com/@Resuvonia" target="_blank">
          <img src="/youtube-logo.png" alt="YouTube" class="social-logo">
        </a>
        <a href="https://www.instagram.com/resuvonia/" target="_blank">
          <img src="/instagram-logo.png" alt="Instagram" class="social-logo">
        </a>
        <a href="https://x.com/Resuvonia" target="_blank">
          <img src="/x-logo.png" alt="Twitter/X" class="social-logo">
        </a>
      </div>

      <!-- FAQ Section added below the original content -->
      <div class="faq-section">
        <h2>Frequently Asked Questions</h2>
        <p><strong>What is Resuvonia?</strong><br>Resuvonia is a platform where you can create a profile with resources like skills, interests, career, and more. Engage in public and private topics, participate in discussions, vote on suggestions, and access free books, articles, news, and research studies.</p>
        
        <p><strong>How do I create or join a private topic?</strong><br>To create a private topic, specify a topic name and a 6-character code. Share this code to allow others to join the private topic.</p>
        
        <p><strong>What can I vote on?</strong><br>You can vote on topics, subtopics, and suggestions, both public and private. Additional votes are available for $0.3 each.</p>
        
        <p><strong>Can I edit and delete suggestions?</strong><br>Yes, you can edit and delete your own suggestions on both public and private topics.</p>
        
        <p><strong>What is the limit for creating topics and suggestions?</strong><br>You can create up to 5 topics per day (maximum 75 characters each) and 10 suggestions per day (maximum 5000 characters each).</p>
        
        <p><strong>Are there any limitations on creating user accounts?</strong><br>You can create up to 3 new user accounts every day per device.</p>
        
        <p><strong>What resources are available for free?</strong><br>Resuvonia offers access to over 770 free books, more than 660 free articles, daily news, and research studies, all with optional tests.</p>
        
        <p><strong>Who is the founder of Resuvonia?</strong><br>Resuvonia was founded by Theodor N. Engøy in September 2024, a Norwegian born on March 3, 2005. He began learning programming in June 2023.</p>
        
        <p><strong>What type of business is Resuvonia connected to?</strong><br>Resuvonia is connected to the sole proprietorship Theodor N. Engøy.</p>
        
        <p><strong>How can I support Resuvonia?</strong><br>You can choose to donate if you’d like to support Resuvonia. Stripe is used for both purchasing votes and making donations.</p>
        
        <p><strong>What moderation tools does Resuvonia use?</strong><br>Resuvonia uses the Google Perspective API to help moderate language and ensure a respectful community environment.</p>
        
        <p><strong>What technology is Resuvonia built with?</strong><br>Resuvonia is built with Vue.js and utilizes ChatGPT-4. It also uses MongoDB for data storage, and the platform is hosted on Netlify and Heroku.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  methods: {
    goBackToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px;
}

.title {
  margin-bottom: 20px;
}

.founder-image {
  max-width: 200px;
  margin-bottom: 20px;
}

.social-media-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-logo {
  width: 50px;
}

.contact-logo {
  margin-bottom: 20px;
}

.faq-section {
  margin-top: 30px;
  text-align: left;
  max-width: 600px;
}
</style>
