<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to User's Topics" 
      @back="goBackToUserTopics"
    />
    <div class="content">
      <h2>{{ username }}'s Suggestions for {{ formattedTopicName }}</h2>
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>

      <ul class="suggestions-list">
        <li v-for="suggestion in sortedSuggestions" :key="suggestion._id">
          <div class="suggestion-box">
            <p><strong>{{ suggestion.username }}</strong></p>
            <p>{{ suggestion.text }}</p>
            <div class="actions">
              <button @click="handleFreeVote(suggestion)">Vote ({{ suggestion.votes || 0 }})</button>
              <button @click="toggleExtraVote(suggestion._id)">Extra Votes</button>
              <div v-if="extraVoteVisible === suggestion._id" class="extra-votes-container">
                <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Extra votes" />
                <button @click="submitExtraVotes(suggestion)">Submit Extra Votes</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="sortedSuggestions.length === 0">No suggestions found for this topic.</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  data() {
    return {
      username: this.$route.params.username,
      topicName: this.$route.params.topicName,
      suggestions: [],
      sortOption: 'mostRecent',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      errorMessage: '',
    };
  },
  computed: {
    sortedSuggestions() {
      let sorted = [...this.suggestions];
      if (this.sortOption === 'mostVoted') {
        return sorted.sort((a, b) => b.votes - a.votes);
      }
      return sorted.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },
    formattedTopicName() {
      return this.topicName;
    }
  },
  async created() {
    await this.loadUserSuggestions();
    await this.getExtraVotes();
  },
  methods: {
    async loadUserSuggestions() {
      try {
        const response = await axiosInstance.get(`/users/${this.username}/topics/${this.topicName}/suggestions`);
        this.suggestions = response.data;
      } catch (error) {
        this.errorMessage = 'Failed to load suggestions.';
        console.error('Error loading user suggestions:', error);
      }
    },
    handleFreeVote(suggestion) {
      this.vote(suggestion, 1);
    },
    toggleExtraVote(suggestionId) {
      this.extraVoteVisible = this.extraVoteVisible === suggestionId ? null : suggestionId;
    },
    async submitExtraVotes(suggestion) {
      const voteCount = parseInt(this.extraVoteCount, 10);
      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/suggestions/${suggestion._id}/extra-vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
        await this.loadUserSuggestions();
      } catch (error) {
        this.errorMessage = 'Failed to submit extra votes.';
        console.error('Error submitting extra votes:', error);
      }
    },
    async vote(suggestion, voteCount) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/suggestions/${suggestion._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        suggestion.votes += voteCount;
      } catch (error) {
        this.errorMessage = 'Failed to vote for the suggestion.';
        console.error('Error voting for suggestion:', error);
      }
    },
    async getExtraVotes() {
      try {
        const response = await axiosInstance.get('/users/extra-votes');
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error);
      }
    },
    goBackToUserTopics() {
      this.$router.push({ name: 'user-topics', params: { username: this.username } });
    },
    sortByMostRecent() {
      this.sortOption = 'mostRecent';
    },
    sortByMostVoted() {
      this.sortOption = 'mostVoted';
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
}

.sorting-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 800px;
}

.suggestion-box {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.extra-votes-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-message {
  color: red;
}
</style>
