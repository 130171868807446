<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Topics" 
      @back="goBackToTopics"
    />
    <div class="content">
      <h2>Search For Users</h2>
      <input v-model="searchQuery" type="text" placeholder="Search for a user" class="search-input" @input="saveSearchQuery" />
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>

      <ul class="users-list">
        <li v-for="user in sortedUsers" :key="user._id">
          <router-link :to="{ name: 'user-topics', params: { username: user.username } }">{{ user.username }}</router-link>
          <div class="actions">
            <button @click="handleFreeVote(user)">Vote ({{ user.votes || 0 }})</button>
            <button @click="toggleExtraVote(user._id)">Extra Votes</button>
            <div v-if="extraVoteVisible === user._id" class="extra-votes-container">
              <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Enter extra votes" />
              <button @click="submitExtraVotes(user)">Submit Extra Votes</button>
            </div>
          </div>
        </li>
      </ul>

      <p v-if="sortedUsers.length === 0">No users found.</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  data() {
    return {
      users: [],
      searchQuery: localStorage.getItem('userSearchQuery') || '',
      sortOption: 'mostRecent',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      errorMessage: '',
      votesGiven: {},
    };
  },
  computed: {
    sortedUsers() {
      let filtered = this.users.filter(user =>
        user.username.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      if (this.sortOption === 'mostVoted') {
        return filtered.sort((a, b) => b.votes - a.votes);
      }
      return filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
  },
  async created() {
    await this.loadUsers();
    await this.getExtraVotes();
  },
  methods: {
    async loadUsers() {
      try {
        const response = await axiosInstance.get('/users');
        this.users = Array.isArray(response.data.users) ? response.data.users : [];
      } catch (error) {
        this.errorMessage = 'Failed to load users.';
        console.error('Error loading users:', error);
      }
    },
    handleFreeVote(user) {
      if (!this.votesGiven[user._id]) {
        this.vote(user, 1);
        this.votesGiven[user._id] = true;
      } else {
        this.errorMessage = "You have already given your free vote.";
      }
    },
    toggleExtraVote(userId) {
      this.extraVoteVisible = this.extraVoteVisible === userId ? null : userId;
    },
    async submitExtraVotes(user) {
      const voteCount = parseInt(this.extraVoteCount, 10);
      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/users/${user._id}/extra-vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
        await this.loadUsers();
      } catch (error) {
        this.errorMessage = 'Failed to submit extra votes.';
        console.error('Error submitting extra votes:', error);
      }
    },
    async vote(user, voteCount = 1) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/users/${user._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await this.loadUsers();
      } catch (error) {
        this.errorMessage = 'Failed to vote for user.';
        console.error('Error voting for user:', error);
      }
    },
    async getExtraVotes() {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axiosInstance.get('/users/extra-votes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error);
      }
    },
    goBackToTopics() {
      this.$router.push('/topics');
    },
    async sortByMostRecent() {
      this.sortOption = 'mostRecent';
      await this.loadUsers();
    },
    async sortByMostVoted() {
      this.sortOption = 'mostVoted';
      await this.loadUsers();
    },
    saveSearchQuery() {
      localStorage.setItem('userSearchQuery', this.searchQuery);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
}

.search-input {
  width: 100%;
  max-width: 800px; /* Ensure it does not exceed the container width */
  padding: 10px;
  margin-bottom: 20px;
}

.sorting-buttons button {
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sorting-buttons button:hover {
  background-color: #555;
}

.users-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 800px;
}

.users-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.extra-votes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.extra-votes-container input {
  width: 100%;
  max-width: 100px;
  padding: 8px;
  box-sizing: border-box;
}

.extra-votes-container button {
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.extra-votes-container button:hover {
  background-color: #555;
}

.error-message {
  color: red;
}

.active {
  background-color: #555;
}
</style>
