<template>
  <div class="container">
    <Header :showBackButton="true" backText="Back to Choose Action" @back="goBackToChooseAction" />
    <div class="content" ref="content">
      <h1 class="centered-title">{{ research.title }}</h1>
      <pre class="centered-content">{{ researchContent }}</pre>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      research: {},
      researchContent: '',
    };
  },
  mounted() {
    const studyId = this.$route.params.studyId;
    this.research = this.getResearchDetails(studyId);

    // Fetch content after loading research details
    this.fetchResearchContent(this.research.text).then(() => {
      this.$nextTick(() => {
        const savedPosition = localStorage.getItem(`scrollPosition_Research_${studyId}`);
        if (savedPosition) {
          this.$refs.content.scrollTop = savedPosition;
        }
      });
    });
  },
  methods: {
    getResearchDetails(studyId) {
      const studies = [
      { id: 1, title: "Artificial Intelligence in Healthcare: Early Detection of Alzheimer’s Disease through Machine Learning", text: "/articles/STUDY-Artificial-Intelligence-in-Healthcare-Early-Detection-of-Alzheimer’s-Disease-through-Machine-Learning.txt", genre: "Artificial Intelligence" },
{ id: 2, title: "Artificial Intelligence in Medical Diagnosis: Improving Accuracy and Speed in Healthcare", text: "/articles/STUDY-Artificial-Intelligence-in-Medical-Diagnosis-Improving-Accuracy-and-Speed-in-Healthcare.txt", genre: "Artificial Intelligence" },
{ id: 3, title: "Caloric Restriction and Longevity: Insights from the Study of Aging in Non-Human Primates", text: "/articles/STUDY-Caloric-Restriction-and-Longevity-Insights-from-the-Study-of-Aging-in-Non-Human-Primates.txt", genre: "Aging & Longevity" },
{ id: 4, title: "CRISPR-Cas9 and Gene Editing: Unlocking the Potential to Treat Genetic Diseases", text: "/articles/STUDY-CRISPR-Cas9-and-Gene-Editing-Unlocking-the-Potential-to-Treat-Genetic-Diseases.txt", genre: "Genetics" },
{ id: 5, title: "CRISPR-Cas9 Gene Editing in the Treatment of Genetic Disorders: A Breakthrough in Precision Medicine", text: "/articles/STUDY-CRISPR-Cas9-Gene-Editing-in-the-Treatment-of-Genetic-Disorders-A-Breakthrough-in-Precision-Medicine.txt", genre: "Genetics" },
{ id: 6, title: "Exercise and Brain Health: The Role of Aerobic Activity in Enhancing Cognitive Function and Neuroplasticity", text: "/articles/STUDY-Exercise-and-Brain-Health-The-Role-of-Aerobic-Activity-in-Enhancing-Cognitive-Function-and-Neuroplasticity.txt", genre: "Neuroscience" },
{ id: 7, title: "Gravitational Waves: A New Window into the Universe", text: "/articles/STUDY-Gravitational-Waves-A-New-Window-into-the-Universe.txt", genre: "Physics" },
{ id: 8, title: "Immunotherapy in Cancer: Harnessing the Body’s Immune System to Fight Tumors", text: "/articles/STUDY-Immunotherapy-in-Cancer-Harnessing-the-Body’s-Immune-System-to-Fight-Tumors.txt", genre: "Cancer Research" },
{ id: 9, title: "Neuroplasticity", text: "/articles/STUDY-Neuroplasticity.txt", genre: "Neuroscience" },
{ id: 10, title: "Pharmacogenomics: Personalized Medicine through Genetic Profiling", text: "/articles/STUDY-Pharmacogenomics-Personalized-Medicine-through-Genetic-Profiling.txt", genre: "Pharmacology" },
{ id: 11, title: "Targeting the Immune System: The Role of Immune Checkpoint Inhibitors in Cancer Treatment", text: "/articles/STUDY-Targeting-the-Immune-System-The-Role-of-Immune-Checkpoint-Inhibitors-in-Cancer-Treatment.txt", genre: "Immunology" },
{ id: 12, title: "Telomere Length and Cellular Aging: The Key to Understanding Human Longevity", text: "/articles/STUDY-Telomere-Length-and-Cellular-Aging-The-Key-to-Understanding-Human-Longevity.txt", genre: "Cell Biology" },
{ id: 13, title: "Telomeres and Aging: Understanding the Biological Clock", text: "/articles/STUDY-Telomeres-and-Aging-Understanding-the-Biological-Clock.txt", genre: "Aging & Longevity" },
{ id: 14, title: "Telomeres and Aging", text: "/articles/STUDY-Telomeres-and-Aging.txt", genre: "Aging & Longevity" },
{ id: 15, title: "The Development of mRNA Vaccines: A New Frontier in Infectious Disease Prevention", text: "/articles/STUDY-The-Development-of-mRNA-Vaccines-A-New-Frontier-in-Infectious-Disease-Prevention.txt", genre: "Biotechnology" },
{ id: 16, title: "The Discovery of Graphene: Revolutionizing Materials Science", text: "/articles/STUDY-The-Discovery-of-Graphene-Revolutionizing-Materials-Science.txt", genre: "Chemistry" },
{ id: 17, title: "The Effects of Climate Change on Polar Ice Sheets: A Study of Melting Rates and Global Sea-Level Rise", text: "/articles/STUDY-The-Effects-of-Climate-Change-on-Polar-Ice-Sheets-A-Study-of-Melting-Rates-and-Global-Sea-Level-Rise.txt", genre: "Environmental Science" },
{ id: 18, title: "The Effects of Mindfulness Meditation on Reducing Stress and Anxiety: A Randomized Controlled Trial", text: "/articles/STUDY-The-Effects-of-Mindfulness-Meditation-on-Reducing-Stress-and-Anxiety-A-Randomized-Controlled-Trial.txt", genre: "Psychology" },
{ id: 19, title: "The Global Impact of Vaccination Programs: Eradication of Smallpox and the Fight Against Infectious Diseases", text: "/articles/STUDY-The-Global-Impact-of-Vaccination-Programs-Eradication-of-Smallpox-and-the-Fight-Against-Infectious-Diseases.txt", genre: "Public Health" },
{ id: 20, title: "The Impact of Clean Water and Sanitation on Reducing Child Mortality: Lessons from Global Health Initiatives", text: "/articles/STUDY-The-Impact-of-Clean-Water-and-Sanitation-on-Reducing-Child-Mortality-Lessons-from-Global-Health-Initiatives.txt", genre: "Public Health" },
{ id: 21, title: "The Impact of Climate Change on Coral Reefs: Assessing the Consequences of Ocean Warming and Acidification", text: "/articles/STUDY-The-Impact-of-Climate-Change-on-Coral-Reefs-Assessing-the-Consequences-of-Ocean-Warming-and-Acidification.txt", genre: "Environmental Science" },
{ id: 22, title: "The Impact of Deforestation on Biodiversity: A Global Study of Tropical Rainforests", text: "/articles/STUDY-The-Impact-of-Deforestation-on-Biodiversity-A-Global-Study-of-Tropical-Rainforests.txt", genre: "Environmental Science" },
{ id: 23, title: "The Impact of Gut Microbiota on Mental Health: Unraveling the Gut-Brain Axis", text: "/articles/STUDY-The-Impact-of-Gut-Microbiota-on-Mental-Health-Unraveling-the-Gut-Brain-Axis.txt", genre: "Immunology" },
{ id: 24, title: "The Impact of Microbiome Diversity on Immune System Function: A Study of Gut Health and Immunity", text: "/articles/STUDY-The-Impact-of-Microbiome-Diversity-on-Immune-System-Function-A-Study-of-Gut-Health-and-Immunity.txt", genre: "Immunology" },
{ id: 25, title: "The Impact of Ocean Acidification on Marine Life: Assessing the Consequences of CO2 Emissions", text: "/articles/STUDY-The-Impact-of-Ocean-Acidification-on-Marine-Life-Assessing-the-Consequences-of-CO2-Emissions.txt", genre: "Environmental Science" },
{ id: 26, title: "The Impact of Vaccination Programs on Eradicating Smallpox: A Global Health Milestone", text: "/articles/STUDY-The-Impact-of-Vaccination-Programs-on-Eradicating-Smallpox-A-Global-Health-Milestone.txt", genre: "Public Health" },
{ id: 27, title: "The Influence of Social Media on Mental Health: A Study on Depression and Anxiety in Adolescents", text: "/articles/STUDY-The-Influence-of-Social-Media-on-Mental-Health-A-Study-on-Depression-and-Anxiety-in-Adolescents.txt", genre: "Psychology" },
{ id: 28, title: "The Role of 3D Bioprinting in Organ Regeneration: A Breakthrough in Tissue Engineering", text: "/articles/STUDY-The-Role-of-3D-Bioprinting-in-Organ-Regeneration-A-Breakthrough-in-Tissue-Engineering.txt", genre: "Molecular Biology" },
{ id: 29, title: "The Role of Antisense Oligonucleotides in Treating Genetic Disorders: A New Frontier in Precision Medicine", text: "/articles/STUDY-The-Role-of-Antisense-Oligonucleotides-in-Treating-Genetic-Disorders-A-New-Frontier-in-Precision-Medicine.txt", genre: "Genetics" },
{ id: 30, title: "The Role of Artificial Intelligence in Drug Discovery: Revolutionizing the Development of New Medicines", text: "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Drug-Discovery-Revolutionizing-the-Development-of-New-Medicines.txt", genre: "Artificial Intelligence" },
{ id: 31, title: "The Role of Artificial Intelligence in Personalized Cancer Treatment: Optimizing Therapies through Predictive Modeling", text: "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Personalized-Cancer-Treatment-Optimizing-Therapies-through-Predictive-Modeling.txt", genre: "Artificial Intelligence" },
{ id: 32, title: "The Role of Artificial Intelligence in Predicting Disease Outbreaks: Enhancing Global Health Surveillance", text: "/articles/STUDY-The-Role-of-Artificial-Intelligence-in-Predicting-Disease-Outbreaks-Enhancing-Global-Health-Surveillance.txt", genre: "Artificial Intelligence" },
{ id: 33, title: "The Role of Artificial Photosynthesis in Carbon Capture: A New Approach to Combating Climate Change", text: "/articles/STUDY-The-Role-of-Artificial-Photosynthesis-in-Carbon-Capture-A-New-Approach-to-Combating-Climate-Change.txt", genre: "Environmental Science" },
{ id: 34, title: "The Role of Bacteriophage Therapy in Combatting Antibiotic Resistance: A New Era in Infection Treatment", text: "/articles/STUDY-The-Role-of-Bacteriophage-Therapy-in-Combatting-Antibiotic-Resistance-A-New-Era-in-Infection-Treatment.txt", genre: "Medical Science" },
{ id: 35, title: "The Role of Biodiversity in Ecosystem Resilience: A Study of Ecosystem Stability in Response to Environmental Change", text: "/articles/STUDY-The-Role-of-Biodiversity-in-Ecosystem-Resilience-A-Study-of-Ecosystem-Stability-in-Response-to-Environmental-Change.txt", genre: "Environmental Science" },
{ id: 36, title: "The Role of Bioprinting in Organ Regeneration: Revolutionizing Transplant Medicine", text: "/articles/STUDY-The-Role-of-Bioprinting-in-Organ-Regeneration-Revolutionizing-Transplant-Medicine.txt", genre: "Molecular Biology" },
{ id: 37, title: "The Role of CAR-T Cell Therapy in Treating Blood Cancers: A Revolution in Immunotherapy", text: "/articles/STUDY-The-Role-of-CAR-T-Cell-Therapy-in-Treating-Blood-Cancers-A-Revolution-in-Immunotherapy.txt", genre: "Cancer Research" },
{ id: 38, title: "The Role of CRISPR-Cas12 in Enhancing Antiviral Therapies: A Study in Precision Medicine", text: "/articles/STUDY-The-Role-of-CRISPR-Cas12-in-Enhancing-Antiviral-Therapies-A-Study-in-Precision-Medicine.txt", genre: "Genetics" },
{ id: 39, title: "The Role of CRISPR-Cas13 in RNA Editing: A New Frontier in Genetic Therapies", text: "/articles/STUDY-The-Role-of-CRISPR-Cas13-in-RNA-Editing-A-New-Frontier-in-Genetic-Therapies.txt", genre: "Genetics" },
{ id: 40, title: "The Role of Epigenetic Reprogramming in Aging: Unlocking the Secrets of Cellular Longevity", text: "/articles/STUDY-The-Role-of-Epigenetic-Reprogramming-in-Aging-Unlocking-the-Secrets-of-Cellular-Longevity.txt", genre: "Genetics" },
{ id: 41, title: "The Role of Epigenetics in Aging: Understanding How Environmental Factors Influence Longevity", text: "/articles/STUDY-The-Role-of-Epigenetics-in-Aging-Understanding-How-Environmental-Factors-Influence-Longevity.txt", genre: "Genetics" },
{ id: 42, title: "The Role of Epigenetics in Cancer: How Environmental Factors Influence Gene Expression", text: "/articles/STUDY-The-Role-of-Epigenetics-in-Cancer-How-Environmental-Factors-Influence-Gene-Expression.txt", genre: "Genetics" },
{ id: 43, title: "The Role of Gene Therapy in Treating Hemophilia: A Breakthrough in Genetic Medicine", text: "/articles/STUDY-The-Role-of-Gene-Therapy-in-Treating-Hemophilia-A-Breakthrough-in-Genetic-Medicine.txt", genre: "Genetics" },
{ id: 44, title: "The Role of Genetic Testing in Personalized Medicine: Tailoring Treatments to Individual Patients", text: "/articles/STUDY-The-Role-of-Genetic-Testing-in-Personalized-Medicine-Tailoring-Treatments-to-Individual-Patients.txt", genre: "Genetics" },
{ id: 45, title: "The Role of Gut-Brain Axis in Neurodegenerative Diseases: Understanding the Link Between Gut Microbiota and Cognitive Decline", text: "/articles/STUDY-The-Role-of-Gut-Brain-Axis-in-Neurodegenerative-Diseases-Understanding-the-Link-Between-Gut-Microbiota-and-Cognitive-Decline.txt", genre: "Neuroscience" },
{ id: 46, title: "The Role of Immunotherapy in Treating Melanoma: The Success of PD-1 Inhibitors", text: "/articles/STUDY-The-Role-of-Immunotherapy-in-Treating-Melanoma-The-Success-of-PD-1-Inhibitors.txt", genre: "Immunology" },
{ id: 47, title: "The Role of Microbial Therapy in Treating Inflammatory Bowel Disease: Harnessing the Power of the Gut Microbiome", text: "/articles/STUDY-The-Role-of-Microbial-Therapy-in-Treating-Inflammatory-Bowel-Disease-Harnessing-the-Power-of-the-Gut-Microbiome.txt", genre: "Immunology" },
{ id: 48, title: "The Role of Mitochondrial DNA in Aging: Unraveling the Link Between Energy Production and Longevity", text: "/articles/STUDY-The-Role-of-Mitochondrial-DNA-in-Aging-Unraveling-the-Link-Between-Energy-Production-and-Longevity.txt", genre: "Molecular Biology" },
{ id: 49, title: "The Role of Nanotechnology in Cancer Treatment: Targeted Drug Delivery and Enhanced Therapeutics", text: "/articles/STUDY-The-Role-of-Nanotechnology-in-Cancer-Treatment-Targeted-Drug-Delivery-and-Enhanced-Therapeutics.txt", genre: "Cancer Research" },
{ id: 50, title: "The Role of Optogenetics in Neuroscience: Unlocking the Brain’s Secrets with Light", text: "/articles/STUDY-The-Role-of-Optogenetics-in-Neuroscience-Unlocking-the-Brain’s-Secrets-with-Light.txt", genre: "Neuroscience" },
{ id: 51, title: "The Role of Quantum Computing in Drug Discovery: Accelerating the Development of New Medicines", text: "/articles/STUDY-The-Role-of-Quantum-Computing-in-Drug-Discovery-Accelerating-the-Development-of-New-Medicines.txt", genre: "Artificial Intelligence" },
{ id: 52, title: "The Role of Senolytic Therapies in Combating Age-Related Diseases: Targeting Senescent Cells for Healthier Aging", text: "/articles/STUDY-The-Role-of-Senolytic-Therapies-in-Combating-Age-Related-Diseases-Targeting-Senescent-Cells-for-Healthier-Aging.txt", genre: "Aging & Longevity" },
{ id: 53, title: "The Role of Sleep in Cognitive Performance: Understanding How Sleep Affects Memory and Learning", text: "/articles/STUDY-The-Role-of-Sleep-in-Cognitive-Performance-Understanding-How-Sleep-Affects-Memory-and-Learning.txt", genre: "Neuroscience" },
{ id: 54, title: "The Role of Sleep in Memory Consolidation: How Sleep Enhances Learning and Retention", text: "/articles/STUDY-The-Role-of-Sleep-in-Memory-Consolidation-How-Sleep-Enhances-Learning-and-Retention.txt", genre: "Neuroscience" },
{ id: 55, title: "The Role of Stem Cells in Regenerative Medicine: Unlocking the Potential for Tissue Repair and Organ Regeneration", text: "/articles/STUDY-The-Role-of-Stem-Cells-in-Regenerative-Medicine-Unlocking-the-Potential-for-Tissue-Repair-and-Organ-Regeneration.txt", genre: "Molecular Biology" },
{ id: 56, title: "The Role of T-Cell Engineering in Cancer Immunotherapy: A New Approach to Targeting Solid Tumors", text: "/articles/STUDY-The-Role-of-T-Cell-Engineering-in-Cancer-Immunotherapy-A-New-Approach-to-Targeting-Solid-Tumors.txt", genre: "Cancer Research" },
{ id: 57, title: "The Role of T-Cell Exhaustion in Cancer Progression: Revitalizing the Immune System to Fight Tumors", text: "/articles/STUDY-The-Role-of-T-Cell-Exhaustion-in-Cancer-Progression-Revitalizing-the-Immune-System-to-Fight-Tumors.txt", genre: "Cancer Research" },
{ id: 58, title: "The Role of Telomere Shortening in Age-Related Diseases: A Study of Cellular Aging and Longevity", text: "/articles/STUDY-The-Role-of-Telomere-Shortening-in-Age-Related-Diseases-A-Study-of-Cellular-Aging-and-Longevity.txt", genre: "Cell Biology" },
{ id: 59, title: "The Role of the Gut Microbiome in Shaping the Immune System: Insights into Autoimmunity and Inflammatory Diseases", text: "/articles/STUDY-The-Role-of-the-Gut-Microbiome-in-Shaping-the-Immune-System-Insights-into-Autoimmunity-and-Inflammatory-Diseases.txt", genre: "Immunology" },
{ id: 60, title: "The Stanford Prison Experiment: Understanding the Power of Situational Forces on Human Behavior", text: "/articles/STUDY-The-Stanford-Prison-Experiment-Understanding-the-Power-of-Situational-Forces-on-Human-Behavior.txt", genre: "Sociology" },
{ id: 61, title: "The Tuskegee Syphilis Study: A Case of Ethical Violations in Medical Research", text: "/articles/STUDY-The-Tuskegee-Syphilis-Study-A-Case-of-Ethical-Violations-in-Medical-Research.txt", genre: "Ethics in Research" }
      ];
      return studies.find(study => study.id === parseInt(studyId));
    },
    fetchResearchContent(url) {
      const encodedUrl = encodeURI(url);
      return fetch(encodedUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error("Failed to load content.");
          }
          return response.text();
        })
        .then(content => {
          this.researchContent = content;
        })
        .catch(error => {
          console.error("Fetch error:", error);
          this.researchContent = "There was an error loading the study's content.";
        });
    },
    goBackToChooseAction() {
      this.saveScrollPosition();
      this.$router.push({ name: 'choose-action', params: { topicType: 'research', topicId: this.research.id, topicName: this.research.title } });
    },
    saveScrollPosition() {
      const studyId = this.$route.params.studyId;
      const scrollPosition = this.$refs.content.scrollTop;
      localStorage.setItem(`scrollPosition_Research_${studyId}`, scrollPosition);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.2;
  font-family: 'Georgia', serif; /* Added font */
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
  font-family: 'Georgia', serif; /* Added font */
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .centered-title {
    font-size: 1.25rem;
  }

  .centered-content {
    font-size: 1rem;
    text-align: left;
  }
}
</style>
