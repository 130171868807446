<template>
  <div class="container">
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <div class="content">
      <h1>Test for "{{ topicName }}"</h1>

      <!-- Show loading message if test not loaded yet -->
      <div v-if="loading">Loading test...</div>

      <!-- Show test questions once loaded -->
      <div v-else>
        <!-- Test questions -->
        <div v-for="(question, index) in testQuestions" :key="index" class="question-item">
          <p>{{ index + 1 }}. {{ question.question }}</p>
          <ul>
            <li
              v-for="(answer, i) in question.answers"
              :key="i"
              :class="{
                correct: submitted && answer === question.correctAnswer,
                wrong: submitted && answer === userAnswers[index] && answer !== question.correctAnswer
              }"
            >
              <input
                type="radio"
                :name="'question-' + index"
                :value="answer"
                v-model="userAnswers[index]"
                :disabled="submitted"
              />
              {{ answer }}
              <!-- Show feedback if the test is submitted -->
              <span v-if="submitted && answer === question.correctAnswer"> - Correct</span>
              <span v-if="submitted && answer === userAnswers[index] && answer !== question.correctAnswer">
                - Your Answer
              </span>
            </li>
          </ul>
        </div>

        <!-- Show submit button if the test is not yet submitted -->
        <div v-if="!submitted">
          <button @click="submitTest" class="action-button">Submit Test</button>
        </div>

        <!-- Show results summary after test submission -->
        <div v-else>
          <h2>Results</h2>
          <p>You got {{ correctAnswers }} out of {{ testQuestions.length }} correct.</p>
          <button @click="restartTest" class="action-button">Restart Test</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      loading: true,
      topicId: null,
      topicName: '', // Change from topicTitle to topicName
      topicType: '',
      testQuestions: [],
      userAnswers: [],
      submitted: false,
      correctAnswers: 0,
    };
  },
  methods: {
    async loadTest() {
      // Get the topicType, topicId, and topicName from the route parameters
      this.topicType = this.$route.params.topicType || 'unknown';
      this.topicId = this.$route.params.topicId;
      this.topicName = this.$route.params.topicName || 'Untitled'; // Fallback if not provided

      try {
        // Dynamically fetch the correct JSON file based on the topic type
        const response = await fetch(`/${this.topicType}_${this.topicId}.json`);

        if (!response.ok) {
          throw new Error('Test not found');
        }

        const test = await response.json();

        // Set the test questions
        this.testQuestions = test.questions;
        this.userAnswers = new Array(this.testQuestions.length).fill(null);

        // Load user's previous result (if any)
        await this.loadPreviousResult();

      } catch (error) {
        console.error('Error loading test:', error);
      }

      this.loading = false;
    },
    async loadPreviousResult() {
      try {
        const response = await fetch(`/api/users/test-result/${this.topicName}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to load previous test result');
        }

        const result = await response.json();
        if (result.success && result.testResult) {
          this.correctAnswers = result.testResult.correctAnswers;
          this.userAnswers = result.testResult.userAnswers || [];
          this.submitted = true;
        }
      } catch (error) {
        console.error('Error loading previous test result:', error);
      }
    },
    goBackToChooseAction() {
      // Go back to the choose action page
      this.$router.push({
        name: 'choose-action',
        params: { topicType: this.topicType, topicId: this.topicId, topicName: this.topicName },
      });
    },
    async submitTest() {
      // Calculate the correct answers
      this.correctAnswers = this.testQuestions.reduce((count, question, index) => {
        return count + (this.userAnswers[index] === question.correctAnswer ? 1 : 0);
      }, 0);

      // Mark the test as submitted
      this.submitted = true;

      // Send the test result to the backend to save it
      try {
        await fetch('/api/users/save-test-result', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            topicName: this.topicName,
            correctAnswers: this.correctAnswers,
            totalQuestions: this.testQuestions.length,
            userAnswers: this.userAnswers,  // Include user answers
          }),
        });
      } catch (error) {
        console.error('Error saving test result:', error);
      }
    },
    restartTest() {
      // Reset the test state
      this.submitted = false;
      this.userAnswers = new Array(this.testQuestions.length).fill(null);
      this.correctAnswers = 0;
    },
  },
  mounted() {
    // Load the test when the component is mounted
    this.loadTest();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Georgia', serif; /* Added font */
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  font-family: 'Georgia', serif; /* Added font */
}

.question-item {
  margin-bottom: 20px;
  font-family: 'Georgia', serif; /* Added font */
}

ul {
  list-style-type: none;
  padding: 0;
  font-family: 'Georgia', serif; /* Added font */
}

.correct {
  color: green;
  font-weight: bold;
  font-family: 'Georgia', serif; /* Added font */
}

.wrong {
  color: red;
  font-weight: bold;
  font-family: 'Georgia', serif; /* Added font */
}

.action-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-family: 'Georgia', serif; /* Added font */
}

.action-button:hover {
  background-color: #555;
}

.results {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Georgia', serif; /* Added font */
}
</style>
