<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Users" 
      @back="goBackToUsers"
    />
    <div class="content">
      <h2>{{ username }}'s Topics</h2>
      <input v-model="searchQuery" type="text" placeholder="Search for a topic" class="search-input" />
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>

      <ul class="topics-list">
        <li v-for="topic in sortedTopics" :key="topic._id">
          <router-link :to="{ name: 'user-topic-in-topics', params: { username, topicName: topic.name } }">
            {{ topic.name }}
          </router-link>
          <div class="actions">
            <button @click="handleFreeVote(topic)">Vote ({{ topic.votes || 0 }})</button>
            <button @click="toggleExtraVote(topic._id)">Extra Votes</button>
            <div v-if="extraVoteVisible === topic._id" class="extra-votes-container">
              <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Enter extra votes" />
              <button @click="submitExtraVotes(topic)">Submit Extra Votes</button>
            </div>
          </div>
        </li>
      </ul>

      <p v-if="sortedTopics.length === 0">No topics available for this user.</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  props: {
    username: { type: String, required: true },
  },
  data() {
    return {
      topics: [],
      searchQuery: '',
      sortOption: 'mostRecent',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      errorMessage: '',
    };
  },
  computed: {
    sortedTopics() {
      let filtered = this.topics.filter(topic =>
        topic.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      if (this.sortOption === 'mostVoted') {
        return filtered.sort((a, b) => b.votes - a.votes);
      }
      return filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },
  async created() {
    await this.loadUserTopics();
    await this.getExtraVotes();
  },
  methods: {
    async loadUserTopics() {
      try {
        const response = await axiosInstance.get(`/users/${this.username}/topics`);
        this.topics = Array.isArray(response.data.topics) ? response.data.topics : [];
      } catch (error) {
        this.errorMessage = 'Failed to load topics for this user.';
        console.error('Error loading user topics:', error);
      }
    },
    handleFreeVote(topic) {
      if (!this.votesGiven[topic._id]) {
        this.vote(topic, 1);
        this.votesGiven[topic._id] = true;
      } else {
        this.errorMessage = "You have already given your free vote.";
      }
    },
    toggleExtraVote(topicId) {
      this.extraVoteVisible = this.extraVoteVisible === topicId ? null : topicId;
    },
    async submitExtraVotes(topic) {
      const voteCount = parseInt(this.extraVoteCount, 10);
      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/topics/${topic._id}/extra-vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
        await this.loadUserTopics();
      } catch (error) {
        this.errorMessage = 'Error submitting extra votes.';
        console.error('Error submitting extra votes:', error.response?.data || error.message);
      }
    },
    async vote(topic, voteCount = 1) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/topics/${topic._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.loadUserTopics();
      } catch (error) {
        this.errorMessage = 'Failed to vote for topic.';
        console.error('Error voting:', error.response?.data || error.message);
      }
    },
    async getExtraVotes() {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axiosInstance.get('/users/extra-votes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error.response?.data || error.message);
      }
    },
    goBackToUsers() {
      this.$router.push('/users');
    },
    sortByMostRecent() {
      this.sortOption = 'mostRecent';
      this.loadUserTopics();
    },
    sortByMostVoted() {
      this.sortOption = 'mostVoted';
      this.loadUserTopics();
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
}

.search-input {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  margin-bottom: 20px;
}

.sorting-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.sorting-buttons button {
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.topics-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 800px;
}

.topics-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.extra-votes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.error-message {
  color: red;
}
</style>
