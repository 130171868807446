import axios from 'axios';

// Set axios to include credentials (cookies, etc.) with each request
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: 'https://shielded-plains-95107-e0431980d96a.herokuapp.com/api', // Ensure this is your backend URL
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000, // Timeout after 60 seconds
});

// Interceptor to automatically attach the JWT token from localStorage for authenticated requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
