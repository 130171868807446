<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to User's Topics" 
      :showGetMoreResourcesButton="true"
      @back="goBackToUserTopics"
      @getMoreResources="goToResourcesPage"
    />
    <div class="content">
      <h1>Subtopics in "{{ topicName }}" by {{ username }}</h1>

      <h2>Search For Subtopics</h2>
      <input v-model="searchQuery" type="text" placeholder="Search for a subtopic" @input="saveSearchQuery" />
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>

      <ul class="topics-list">
        <li v-for="subtopic in sortedSubTopics" :key="subtopic._id">
          <router-link :to="{ name: 'user-suggestions', params: { username, topicName, subtopicName: subtopic.name } }">{{ subtopic.name }}</router-link>
          <div class="actions">
            <button @click="handleFreeVote(subtopic)">Vote ({{ subtopic.votes || 0 }})</button>
            <button @click="toggleExtraVote(subtopic._id)">Extra Votes</button>
            <div v-if="extraVoteVisible === subtopic._id" class="extra-votes-container">
              <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Enter extra votes" />
              <button @click="submitExtraVotes(subtopic)">Submit Extra Votes</button>
            </div>
          </div>
        </li>
      </ul>

      <p v-if="sortedSubTopics.length === 0">No subtopics available.</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  props: {
    username: { type: String, required: true },
    topicName: { type: String, required: true },
  },
  data() {
    return {
      subtopics: [],
      searchQuery: localStorage.getItem('userSubtopicSearchQuery') || '',
      errorMessage: '',
      sortOption: 'mostRecent',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      votesGiven: {},
    };
  },
  computed: {
    sortedSubTopics() {
      let filtered = this.subtopics.filter(subtopic => subtopic.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      if (this.sortOption === 'mostVoted') {
        return filtered.sort((a, b) => b.votes - a.votes);
      }
      return filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },
  async created() {
    await this.loadUserSubTopics();
    await this.getExtraVotes();
  },
  methods: {
    async loadUserSubTopics() {
      try {
        const response = await axiosInstance.get(`/users/${this.username}/topics/${this.topicName}/subtopics`);
        this.subtopics = Array.isArray(response.data.subtopics) ? response.data.subtopics : [];
      } catch (error) {
        this.errorMessage = 'Failed to load subtopics.';
        console.error('Error loading subtopics:', error);
      }
    },
    async handleFreeVote(subtopic) {
      if (!this.votesGiven[subtopic._id]) {
        await this.vote(subtopic, 1);
        this.votesGiven[subtopic._id] = true;
      } else {
        this.errorMessage = "You have already given your free vote.";
      }
    },
    toggleExtraVote(subtopicId) {
      this.extraVoteVisible = this.extraVoteVisible === subtopicId ? null : subtopicId;
    },
    async submitExtraVotes(subtopic) {
      const voteCount = parseInt(this.extraVoteCount, 10);

      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/subtopics/${subtopic._id}/extra-vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
        await this.loadUserSubTopics();
      } catch (error) {
        this.errorMessage = 'Error submitting extra votes.';
        console.error('Error submitting extra votes:', error.response?.data || error.message);
      }
    },
    async vote(subtopic, voteCount = 1) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/subtopics/${subtopic._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.loadUserSubTopics();
      } catch (error) {
        this.errorMessage = 'Failed to vote for subtopic.';
        console.error('Error voting:', error.response?.data || error.message);
      }
    },
    async getExtraVotes() {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axiosInstance.get('/users/extra-votes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error.response?.data || error.message);
      }
    },
    goBackToUserTopics() {
      this.$router.push({ name: 'user-topics', params: { username: this.username } });
    },
    goToResourcesPage() {
      this.$router.push('/resources');
    },
    async sortByMostRecent() {
      this.sortOption = 'mostRecent';
      await this.loadUserSubTopics();
    },
    async sortByMostVoted() {
      this.sortOption = 'mostVoted';
      await this.loadUserSubTopics();
    },
    saveSearchQuery() {
      localStorage.setItem('userSubtopicSearchQuery', this.searchQuery);
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.actions {
  display: flex;
  align-items: center;
}

.sorting-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.sorting-buttons button,
.actions button {
  margin: 0 10px;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sorting-buttons button:hover,
.actions button:hover {
  background-color: #555;
}

.active {
  background-color: #555;
}

.extra-votes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.extra-votes-container input {
  width: 100%;
  max-width: 100px;
  padding: 8px;
  box-sizing: border-box;
}

.extra-votes-container button {
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.extra-votes-container button:hover {
  background-color: #555;
}

.error-message {
  color: red;
}
</style>
