// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Include the store
import axiosInstance from '@/axiosConfig';

const app = createApp(App);

// Make axios available globally in the app
app.config.globalProperties.$axios = axiosInstance;

app.use(router);
app.use(store);

// Check for token in localStorage and set authentication status
const token = localStorage.getItem('jwtToken');
if (token) {
  store.dispatch('setAuthenticated', true); // A Vuex action to set user authentication status
} else {
  store.dispatch('setAuthenticated', false);
}

app.mount('#app');
