<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Topics" 
      :showLogoutButton="true" 
      @back="goToTopics"
    />
    <div class="content">
      <h1>Payment Successful</h1>
      <p>Your purchase was successful! You have been granted extra votes.</p>
      <button class="create-button" @click="goToTopics">Go to Topics</button>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  methods: {
    goToTopics() {
      this.$router.push('/topics'); // Redirect to TopicsPage
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.create-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.create-button:hover {
  background-color: #555;
}
</style>
