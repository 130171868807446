import { createStore } from 'vuex';

export default createStore({
  state: {
    isAuthenticated: false,
    scrollPositions: {},
    articleSearchQuery: '',
    newsSearchQuery: '',
    bookSearchQuery: '',
    selectedBookGenre: '',
    selectedArticleGenre: '',
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    getScrollPosition: (state) => (pageId) => state.scrollPositions[pageId] || 0,
    getArticleSearchQuery: (state) => state.articleSearchQuery,
    getBookSearchQuery: (state) => state.bookSearchQuery,
    getSelectedBookGenre: (state) => state.selectedBookGenre,
    getSelectedArticleGenre: (state) => state.selectedArticleGenre,
  },
  mutations: {
    setScrollPosition(state, { pageId, position }) {
      state.scrollPositions[pageId] = position;
    },
    setArticleSearchQuery(state, query) {
      state.articleSearchQuery = query;
    },
    setBookSearchQuery(state, query) {
      state.bookSearchQuery = query;
    },
  },
  actions: {
    saveScrollPosition({ commit }, payload) {
      return fetch('/api/save-scroll', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(() => {
          commit('setScrollPosition', payload);
        });
    },
    loadScrollPosition({ commit }, { pageId, username }) {
      return fetch(`/api/get-scroll?username=${username}&pageId=${pageId}`)
        .then(response => response.json())
        .then(data => {
          commit('setScrollPosition', { pageId, position: data.scrollPosition });
        });
    },
  },
});
